import {logger} from './Logger';
import * as Utils from './Utils';

export class ViewportTracker {
  private onIntersectionChanged: () => void;
  private observer?: IntersectionObserver;
  private element: HTMLElement;
  private inViewport?: boolean = undefined;
  private threshold: number;
  private hidden: string | undefined;
  private visibilityChange?: string;

  constructor(element: HTMLElement, onIntersectionChanged: () => void, threshold = 1.0) {
    this.threshold = threshold;
    this.onIntersectionChanged = onIntersectionChanged;
    this.element = element;

    try {
      const options: IntersectionObserverInit = {
        root: null,
        rootMargin: '0px',
        threshold,
      };
      this.observer = new IntersectionObserver((entries, observer) => this.handleIntersect(entries, observer), options);
      this.observer.observe(element);
    } catch (_error) {
      logger.log("Couldn't create instance of IntersectionObserver. timeInViewport will always be reported as 100%.");
    }

    this.hidden = Utils.getHiddenProp();
    if (this.hidden) {
      this.visibilityChange = this.hidden.replace('hidden', '') + 'visibilitychange';
      document.addEventListener(
        this.visibilityChange,
        (this.handleVisibilityChange = this.handleVisibilityChange.bind(this)),
        false,
      );
    }
  }

  isHidden() {
    return this.hidden && document[this.hidden];
  }

  isInViewport(): boolean {
    return !this.isHidden() && (this.inViewport == null ? true : this.inViewport);
  }

  dispose() {
    if (this.observer) {
      this.observer.unobserve(this.element);
      this.observer.disconnect();
    }
    if (this.visibilityChange) {
      document.removeEventListener(this.visibilityChange, this.handleVisibilityChange, false);
    }
  }

  private handleVisibilityChange() {
    this.onIntersectionChanged();
  }

  private handleIntersect(entries: IntersectionObserverEntry[], _observer: IntersectionObserver) {
    entries.forEach((i) => {
      if (i.target === this.element) {
        this.inViewport = !(i.intersectionRatio < this.threshold);
      }
    });
    this.onIntersectionChanged();
  }
}
