import type {EmptyObject} from '@bitmovin/player-web-x/framework-types/BaseTypes';
import type {AnyPackage} from '@bitmovin/player-web-x/framework-types/package-api/PackageApi';

import {BitmovinPwxAdapter} from './BitmovinPwxAdapter';
import {
  BundleExportNames,
  CoreExportNames,
  PlaybackApiEventsPackageExportNames,
  SourceExportNames,
  SourcesApiExportNames,
} from './ExportNames';
import type {AnalyticsContext, CreatePackage} from './Types';

/**
 * Copy&pasted from `@bitmovin/player-web-x` !
 *
 * We need `createPackage` function from util file to create a PWX package, but because `@bitmovin/player-web-x` is not
 * ES5 compliant, we had to copy it here.
 *
 * @see https://github.com/bitmovin-engineering/player-web-phoenix-framework/blob/main/src/package-api/PackageApiUtils.ts#L9
 */
const createPackageFn: CreatePackage = (name, onAdd, dependencies) => {
  (onAdd as any)._name = name;

  return {
    onAdd,
    dependencies,
  } as unknown as AnyPackage<EmptyObject>;
};

export const BitmovinPwxAnalyticsPackage = createPackageFn(
  'analytics-package',
  (_, baseContext) => {
    /*
      Player Web X analytics package.
      It is being run for every source added - so there is no actual source switching per analytics instance.
      Package is instantiating the adapter, and making sure it is disposed once package is aborted.
    */

    const {EventListenerEffectFactory, StateEffectFactory} = baseContext.registry.get(CoreExportNames.CoreEffects);
    const context = baseContext.using(EventListenerEffectFactory, StateEffectFactory) as unknown as AnalyticsContext;
    const logger = context.registry.get(BundleExportNames.Logger);
    const source = context.registry.get(SourceExportNames.SourceState);
    const playerConfig = context.registry.get(BundleExportNames.PlayerConfig);
    const analyticsConfig = playerConfig.analytics ?? {};
    analyticsConfig.playerKey = analyticsConfig.playerKey ?? playerConfig.key;

    logger.log(`Applying analytics config ${JSON.stringify(analyticsConfig)}`);
    const adapter = new BitmovinPwxAdapter(context, analyticsConfig);

    logger.log(`Analytics package initializing for "${source.url}"...`);

    const listener = () => {
      context.abortSignal.removeEventListener('abort', listener);
      logger.log(`Analytics package disposing for "${source.url}"`);
      adapter.dispose();
    };

    context.abortSignal.addEventListener('abort', listener);
  },
  [
    BundleExportNames.Logger,
    BundleExportNames.PlayerConfig,
    CoreExportNames.Constants,
    CoreExportNames.CoreEffects,
    CoreExportNames.CoreStateAtoms,
    SourceExportNames.SourceState,
    SourcesApiExportNames.SourceEventBus,
    PlaybackApiEventsPackageExportNames.PlaybackEvent,
  ],
) as any; // we cast package as any to prevent DTS bundle type merging
