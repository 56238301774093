export default class HTMLVideoElementStatisticsProvider {
  private previousTotalDroppedFrames = 0;

  constructor(private videoElement: HTMLVideoElement | undefined) {}

  getDroppedFrames(): number {
    if (this.videoElement == undefined) {
      return 0;
    }

    // this API is not supported in all browsers (especially on iOS Safari it was added quite late)
    // reference: https://developer.mozilla.org/en-US/docs/Web/API/VideoPlaybackQuality
    if (this.videoElement.getVideoPlaybackQuality === undefined) {
      return 0;
    }

    const droppedFrames = this.videoElement.getVideoPlaybackQuality().droppedVideoFrames;
    const currentDroppedFrames = droppedFrames - this.previousTotalDroppedFrames;
    this.previousTotalDroppedFrames = droppedFrames;
    return currentDroppedFrames;
  }
  reset() {
    this.previousTotalDroppedFrames = 0;
  }
}
