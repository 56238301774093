const videoCodecs = ['avc1', 'avc3', 'hvc1', 'av01', 'av1', 'hev1', 'vp9'];

const audioCodecs = ['mp4a', 'ec-3', 'ac-3', 'opus', 'audio/mp4'];

export function isVideoCodec(codec: string): boolean {
  if (!codec) {
    return false;
  }
  const index = videoCodecs.findIndex((videoCodec) => codec.startsWith(videoCodec));
  return index !== -1;
}

export function isAudioCodec(codec: string): boolean {
  if (!codec) {
    return false;
  }
  const index = audioCodecs.findIndex((videoCodec) => codec.startsWith(videoCodec));
  return index !== -1;
}
