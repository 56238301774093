export const MESSAGE_NAMESPACE = 'urn:x-cast:com.bitmovin.analytics.cast';
export const ANALYTICS_BACKEND_BASE_URL = 'https://analytics-ingress-global.bitmovin.com';

export const ANALYTICS_LICENSECALL_TIMEOUT = 1000 * 60;
export const ANALYTICS_VIDEOSTART_TIMEOUT = 1000 * 60; // in milliseconds

export const ANALYTICS_QUALITY_CHANGE_COUNT_THRESHOLD = 50;
export const ANALYTICS_QUALITY_CHANGE_COUNT_RESET_INTERVAL = 60 * 60 * 1000;

/**
 * Time (in milliseconds) of consecutive buffering until a REBUFFER_TIMEOUT_ERROR is raised.
 */
export const ANALYTICS_REBUFFER_TIMEOUT = 1000 * 60 * 2;

/**
 * minimal time (in seconds) a seek event should have in order to be recogniced as seek
 */
export const ANALYTICS_MIN_SEEK_DIFFERENCE_THRESHOLD = 3;
