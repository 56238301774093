import {Event} from '../../../enums/Event';
import {AnalyticsEventBase} from '../../../types/EventData';
import {EventCallbackDispatcher} from '../../internal/EventCallbackDispatcher';
import {PlayerContext} from '../player/AmazonIVSPlayerContext';

enum State {
  IDLE,
  PAUSE_SCHEDULED,
  PAUSE_CANCELLED,
}

export class PauseEventDispatcher {
  private readonly EVENT_DELAY_MS = 10;
  private state: State = State.IDLE;

  constructor(
    private dispatcher: EventCallbackDispatcher,
    private playerContext: PlayerContext,
  ) {}

  /**
   * In case of pause events emitted right before the real event
   * this method is used to cancel the scheduled pause event
   * Know cases:
   *  BUFFERING: when buffer runs out `onPause` and `onRebuffering` are
   *    triggered simultanously causing inconsistency which event comes first
   *  PLAYING: when playback continues in vod there is a series of events triggered
   *    play -> playing -> pause -> play -> playing.
   *    in order to not transition into pause again we cancel the one in between the play/playing events
   *  SEEKING (VOD): right before a seek/seeked event a Pause Event is triggered
   */
  cancelPause() {
    if (this.state == State.PAUSE_SCHEDULED) {
      this.state = State.PAUSE_CANCELLED;
    }
  }

  /**
   * schedules an pause event in the very near future
   * scheduled event is cancelable using `cancelPause`
   */
  schedulePauseEvent() {
    if (this.state == State.PAUSE_SCHEDULED) {
      return;
    }

    this.state = State.PAUSE_SCHEDULED;
    const eventObject: AnalyticsEventBase = {
      currentTime: this.playerContext.position,
    };

    setTimeout(
      () => {
        this.triggerPause(eventObject);
        this.state = State.IDLE;
      },
      this.EVENT_DELAY_MS,
      this,
    );
  }

  private triggerPause(event: AnalyticsEventBase) {
    if (this.state == State.PAUSE_CANCELLED) {
      return;
    }

    this.dispatcher.eventCallback(Event.PAUSE, event);
  }
}
