import * as Utils from './Utils';

export class Converter {
  static bytesToBits(bytes: number): number {
    return bytes * 8;
  }
  static bitsToBytes(bits: number): number {
    return Math.round(bits / 8);
  }
  static bytesToKiloBytes(bytes: number): number {
    return Utils.calculateTime(bytes * 1000);
  }
  static kiloBytesToMegaBytes(kiloByte: number): number {
    return kiloByte * 1000;
  }
}
