import {AnalyticsConfig} from './AnalyticsConfig';
import {CustomDataValues} from './CustomDataValues';
import type {AnalyticsEventBase, ErrorEvent, VideoStartFailedEvent} from './EventData';
import {Sample} from './Sample';

export type HeartbeatPayload = {
  /** Flag indicating that the event was triggered by SSAI */
  triggeredBySsai?: boolean;
} & (Pick<Sample, 'buffered'> | Pick<Sample, 'paused'> | Pick<Sample, 'played'>);

export interface StateMachineCallbacks {
  setup: (duration: number, state: string) => void;
  startup: (duration: number, state: string) => void;
  playing: (duration: number, state: string) => void;
  heartbeat: (duration: number, state: string, payload: HeartbeatPayload) => void;
  qualitychange: (duration: number, state: string) => void;
  qualitychange_pause: (duration: number, state: string) => void;
  qualitychange_rebuffering: (duration: number, state: string) => void;
  videoChange: (event: any) => void;
  audioChange: (event: any) => void;
  audiotrack_changing: () => void;
  pause: (duration: number, state: string) => void;
  paused_seeking: (duration: number, state: string) => void;
  end_play_seeking: (duration: number, state: string) => void;
  rebuffering: (duration: number, state: string) => void;
  error: (event: Readonly<ErrorEvent>) => void;
  end: (duration: number, state: string) => void;
  unload: (duration: number, state: string) => void;
  ad: (duration: number, state: string) => void;
  mute: () => void;
  unMute: () => void;
  subtitle_changing: () => void;
  setVideoTimeEndFromEvent: (event: AnalyticsEventBase | any) => void;
  setVideoTimeStartFromEvent: (event: AnalyticsEventBase | any) => void;
  videoStartFailed: (event: Readonly<VideoStartFailedEvent>, sendRequest: boolean) => void;
  startCasting: (duration: number, event: any) => void;
  casting: (duration: number, event: any) => void;
  manualSourceChange: (event: {config: AnalyticsConfig}) => void;
  initialSourceChange: (event: {config: AnalyticsConfig}) => void;
  muting_ready: (duration: number, state: string, event: any) => void;
  customdatachange: (duration?: number, state?: string, event?: {values: CustomDataValues}) => void;

  /** shuts down the collection and removes/clears all timeouts, intervals, event listeners */
  release: () => void;
  playlistTransition: (event: Readonly<AnalyticsEventBase>) => void;
}

/**
 * {@link StateMachineCallbacks} object with each callback set to be empty function (doing nothing).
 *
 * Why?:
 *  We need to set it as default value in {@link InternalAdapter} for use case, when {@link InternalAdapter}
 *  is already created and listen to player events, but {@link Analytics} was not instantiated/initialized yet (that
 *  should set correct {@link StateMachineCallbacks} implementation)
 */
export const defaultStateMachineCallbacks: StateMachineCallbacks = {
  setup: () => {},
  startup: () => {},
  playing: () => {},
  heartbeat: () => {},
  qualitychange: () => {},
  qualitychange_pause: () => {},
  qualitychange_rebuffering: () => {},
  videoChange: () => {},
  audioChange: () => {},
  audiotrack_changing: () => {},
  pause: () => {},
  paused_seeking: () => {},
  end_play_seeking: () => {},
  rebuffering: () => {},
  error: () => {},
  end: () => {},
  unload: () => {},
  ad: () => {},
  mute: () => {},
  unMute: () => {},
  subtitle_changing: () => {},
  setVideoTimeEndFromEvent: () => {},
  setVideoTimeStartFromEvent: () => {},
  videoStartFailed: () => {},
  startCasting: () => {},
  casting: () => {},
  manualSourceChange: () => {},
  initialSourceChange: () => {},
  muting_ready: () => {},
  customdatachange: () => {},
  release: () => {},
  playlistTransition: () => {},
};
