/*!
 * This file was created to provide Bitmovin Player Web X "export names" as constants,
 * because they are exported as enums in 'd.ts' files, that we can't import as value in this project.
 *
 * Once `@bitmovin/player-web-x` bundle will provide these enums in way we could import them, we could remove/simplify this file.
 */

import type {BundleExportNames as BundleExportNamesEnum} from '@bitmovin/player-web-x/types/bundles/Types';
import type {CoreExportNames as CoreExportNamesEnum} from '@bitmovin/player-web-x/types/packages/core/Types';
import type {PlaybackApiEventsPackageExportNames as PlaybackApiEventsPackageExportNamesEnum} from '@bitmovin/player-web-x/types/packages/playback-api/playback-api-events/Types';
import type {SourceExportNames as SourceExportNamesEnum} from '@bitmovin/player-web-x/types/packages/source/Types';
import type {SourcesApiExportNames as SourcesApiExportNameEnum} from '@bitmovin/player-web-x/types/packages/sources-api/Types';

import type {EnumAsUnion} from '../../utils/EnumAsUnion';

const BundleExportNamesLogger: EnumAsUnion<typeof BundleExportNamesEnum> = 'logger';
const BundleExportNamesPlayerConfig: EnumAsUnion<typeof BundleExportNamesEnum> = 'player-config';
export const BundleExportNames = {
  Logger: BundleExportNamesLogger,
  PlayerConfig: BundleExportNamesPlayerConfig,
};

const CoreExportNamesUtils: EnumAsUnion<typeof CoreExportNamesEnum> = 'utils';
const CoreExportNamesConstants: EnumAsUnion<typeof CoreExportNamesEnum> = 'constants';
const CoreExportNamesCoreEffects: EnumAsUnion<typeof CoreExportNamesEnum> = 'core-effects';
const CoreExportNamesCoreStateAtoms: EnumAsUnion<typeof CoreExportNamesEnum> = 'core-state-atoms';
export const CoreExportNames = {
  Utils: CoreExportNamesUtils,
  Constants: CoreExportNamesConstants,
  CoreEffects: CoreExportNamesCoreEffects,
  CoreStateAtoms: CoreExportNamesCoreStateAtoms,
} as const;

const PlaybackApiEventsPackageExportNamesPlaybackEvent: EnumAsUnion<typeof PlaybackApiEventsPackageExportNamesEnum> =
  'playback-event';
export const PlaybackApiEventsPackageExportNames = {
  PlaybackEvent: PlaybackApiEventsPackageExportNamesPlaybackEvent,
} as const;

const SourceExportNamesCreateSourceStateAtom: EnumAsUnion<typeof SourceExportNamesEnum> = 'create-source-state-atom';
const SourceExportNamesCreateSourceReference: EnumAsUnion<typeof SourceExportNamesEnum> = 'create-source-reference';
const SourceExportNamesSourceReferences: EnumAsUnion<typeof SourceExportNamesEnum> = 'source-references';
const SourceExportNamesSourceState: EnumAsUnion<typeof SourceExportNamesEnum> = 'source-state-atom';
export const SourceExportNames = {
  CreateSourceStateAtom: SourceExportNamesCreateSourceStateAtom,
  CreateSourceReference: SourceExportNamesCreateSourceReference,
  SourceReferences: SourceExportNamesSourceReferences,
  SourceState: SourceExportNamesSourceState,
} as const;

const SourcesApiExportNamesSourceApiManager: EnumAsUnion<typeof SourcesApiExportNameEnum> = 'source-api-manager';
const SourcesApiExportNamesVideoElementUtils: EnumAsUnion<typeof SourcesApiExportNameEnum> = 'video-element-utils';
const SourcesApiExportNamesSourceEventBus: EnumAsUnion<typeof SourcesApiExportNameEnum> = 'source-event-bus';
export const SourcesApiExportNames = {
  SourceApiManager: SourcesApiExportNamesSourceApiManager,
  VideoElementUtils: SourcesApiExportNamesVideoElementUtils,
  SourceEventBus: SourcesApiExportNamesSourceEventBus,
} as const;
