import type {AnalyticsConfig} from '../types/AnalyticsConfig';
import {deleteCookie, getCookie, setCookie} from '../utils/documentCookieUtils';
import {isBlank, isValidString} from '../utils/stringUtils';
import * as Utils from '../utils/Utils';
import {isNumber} from '../utils/Utils';

export const COOKIE_NAME_BITMOVIN_ANALYTICS_UUID = 'bitmovin_analytics_uuid';
/** One year in seconds */
export const COOKIE_DEFAULT_MAX_AGE = 365 * 24 * 60 * 60;

export class SessionPersistenceHandler {
  readonly userId: string;

  constructor(config: AnalyticsConfig) {
    const collectorConfig = config.config;

    const shouldNotUseCookies = collectorConfig?.cookiesEnabled === false;

    if (shouldNotUseCookies) {
      deleteCookie(COOKIE_NAME_BITMOVIN_ANALYTICS_UUID);
      this.userId = Utils.generateUUID();
      return;
    }

    const existingUserId = getCookie(COOKIE_NAME_BITMOVIN_ANALYTICS_UUID);
    this.userId = !isBlank(existingUserId) ? existingUserId : Utils.generateUUID();

    const cookieDomain = validateCookieDomainValue(collectorConfig?.cookiesDomain);
    const cookieMaxAge = validateCookieMaxAgeValue(collectorConfig?.cookiesMaxAge);

    // we set also expires, because max-age is not supported on old browsers like IE8
    const nowMillis = Date.now();
    const expirationDate = new Date(nowMillis + cookieMaxAge * 1000);
    const cookieExpires = expirationDate.toUTCString();

    setCookie(COOKIE_NAME_BITMOVIN_ANALYTICS_UUID, this.userId, {
      domain: cookieDomain,
      expires: cookieExpires,
      maxAge: cookieMaxAge,
    });
  }
}

export const validateCookieDomainValue = (value: unknown): string | undefined => {
  if (value == null) {
    return undefined;
  }

  if (!isValidString(value)) {
    return undefined;
  }

  if (isBlank(value)) {
    return undefined;
  }

  return encodeURIComponent(value);
};

export const validateCookieMaxAgeValue = (value: unknown): number => {
  if (value == null) {
    return COOKIE_DEFAULT_MAX_AGE;
  }

  if (!isNumber(value)) {
    return COOKIE_DEFAULT_MAX_AGE;
  }

  return value;
};
