import {SpeedMeasurement} from '../types/SpeedMeasurement';

export class Measure implements SpeedMeasurement {
  private download: SpeedMeasurement;

  get speed(): number {
    return this.download.size / this.download.duration;
  }
  get duration(): number {
    return this.download.duration;
  }
  get size(): number {
    return this.download.size;
  }
  get timeToFirstByte(): number {
    return this.download.timeToFirstByte;
  }
  get timestamp(): Date {
    return this.download.timestamp;
  }

  get httpStatus(): number {
    return this.download.httpStatus;
  }

  constructor(download: SpeedMeasurement) {
    this.download = download;
  }
}
