import type {CodecInfo} from '../types/CodecInfo';
import type {PlaybackInfo} from '../types/PlaybackInfo';
import {isBlank} from '../utils/stringUtils';

/**
 * This class is used to store source specific information for the player
 * mainly as a fallback if sourceInfo cannot be determined by the player since the source is already unloaded
 */
export class SourceInfoFallbackService {
  private streamFormat: PlaybackInfo['streamFormat'];
  private audioCodec: PlaybackInfo['audioCodec'];
  private videoCodec: PlaybackInfo['videoCodec'];

  reset() {
    this.streamFormat = undefined;
    this.audioCodec = undefined;
    this.videoCodec = undefined;
  }

  setStreamFormat(streamFormat: PlaybackInfo['streamFormat']) {
    this.streamFormat = streamFormat;
  }

  applyStreamFormat(playbackInfo: PlaybackInfo) {
    if (isBlank(this.streamFormat)) {
      return;
    }
    playbackInfo.streamFormat = this.streamFormat;
  }

  /**
   * Apply fallback values to {@link PlaybackInfo} if available.
   * If provided {@link CodecInfo} values are available, we store them as new fallback values, before we apply them.
   */
  applyAndStoreCodecInfo(playbackInfo: PlaybackInfo, codecInfo: CodecInfo | undefined) {
    if (codecInfo?.audioCodec != null) {
      this.audioCodec = codecInfo.audioCodec;
    }
    if (codecInfo?.videoCodec != null) {
      this.videoCodec = codecInfo.videoCodec;
    }

    if (!isBlank(this.audioCodec)) {
      playbackInfo.audioCodec = this.audioCodec;
    }
    if (!isBlank(this.videoCodec)) {
      playbackInfo.videoCodec = this.videoCodec;
    }
  }
}
