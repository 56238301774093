import * as Utils from '../utils/Utils';

import {CustomDataValues} from './CustomDataValues';
import {Sample} from './Sample';

export class AdAnalyticsSample implements CustomDataValues {
  adModule?: string;
  adModuleVersion?: string;
  videoImpressionId?: string;
  userAgent?: string;
  language?: string;
  cdnProvider?: string;
  customData1?: string;
  customData2?: string;
  customData3?: string;
  customData4?: string;
  customData5?: string;
  customData6?: string;
  customData7?: string;
  customData8?: string;
  customData9?: string;
  customData10?: string;
  customData11?: string;
  customData12?: string;
  customData13?: string;
  customData14?: string;
  customData15?: string;
  customData16?: string;
  customData17?: string;
  customData18?: string;
  customData19?: string;
  customData20?: string;
  customData21?: string;
  customData22?: string;
  customData23?: string;
  customData24?: string;
  customData25?: string;
  customUserId?: string;
  experimentName?: string;
  domain?: string;
  key?: string;
  path?: string;
  player?: string;
  playerKey?: string;
  playerTech?: string;
  screenHeight?: number;
  screenWidth?: number;
  version?: string;
  size?: string;
  userId?: string;
  videoId?: string;
  videoTitle?: string;
  videoWindowHeight?: number;
  videoWindowWidth?: number;
  playerStartupTime?: number;
  analyticsVersion?: string;
  pageLoadTime?: number;
  pageLoadType?: number;
  autoplay?: boolean;
  platform = 'web';
  audioCodec?: string;
  videoCodec?: string;

  constructor(sample?: Sample) {
    if (!sample) {
      return;
    }
    this.videoImpressionId = sample.impressionId;
    this.userAgent = sample.userAgent;
    this.language = sample.language;
    this.cdnProvider = sample.cdnProvider;

    Utils.transferCustomDataFields(sample, this);

    this.customUserId = sample.customUserId;
    this.domain = sample.domain;
    this.experimentName = sample.experimentName;
    this.key = sample.key;
    this.path = sample.path;
    this.player = sample.player;
    this.playerKey = sample.playerKey;
    this.playerTech = sample.playerTech;
    this.screenHeight = sample.screenHeight;
    this.screenWidth = sample.screenWidth;
    this.version = sample.version;
    this.size = sample.size;
    this.userId = sample.userId;
    this.videoId = sample.videoId;
    this.videoTitle = sample.videoTitle;
    this.videoWindowHeight = sample.videoWindowHeight;
    this.videoWindowWidth = sample.videoWindowWidth;
    this.audioCodec = sample.audioCodec;
    this.videoCodec = sample.videoCodec;
  }
}
