import {AdAnalyticsSample} from '../types/AdAnalyticsSample';
import {AdBreakSample} from '../types/AdBreakSample';
import {AdSample} from '../types/AdSample';
import {Sample} from '../types/Sample';

import {Backend} from './Backend';

/**
 * The `NoOpBackend` is a no-operation implementation of the `Backend` interface.
 * It serves as a placeholder backend when the actual `Backend` functionality is disabled or not required.
 * E.g. in scenarios such as releasing the adapter or when the Collector is disabled via {@link CollectorConfig#enabled}.
 *
 * Using `NoOpBackend` ensures that the code does not need to handle cases where `Backend` might be undefined,
 * thereby simplifying the logic and reducing the need for null or undefined checks.
 *
 * @implements {Backend}
 */
export class NoOpBackend implements Backend {
  sendRequest(_sample: Sample) {
    // empty
  }
  sendUnloadRequest(_sample: Sample) {
    // empty
  }
  sendRequestSynchronous(_sample: Sample) {
    // empty
  }
  sendAdRequest(_sample: AdAnalyticsSample & AdBreakSample & AdSample) {
    // empty
  }
}
