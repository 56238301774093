export class ErrorCode {
  static BITMOVIN_PLAYER_LICENSING_ERROR = new ErrorCode(1103, '');
  static SETUP_MISSING_LICENSE_WHITELIST = new ErrorCode(1105, '');
  static QUALITY_CHANGE_THRESHOLD_EXCEEDED = new ErrorCode(10000, 'ANALYTICS_QUALITY_CHANGE_THRESHOLD_EXCEEDED');
  static BUFFERING_TIMEOUT_REACHED = new ErrorCode(10001, 'ANALYTICS_BUFFERING_TIMEOUT_REACHED');
  static VIDEO_STARTUP_TIMEOUT_REACHED = new ErrorCode(10002, 'ANALYTICS_VIDEOSTART_TIMEOUT_REACHED');

  private constructor(
    readonly code: number,
    readonly message: string,
  ) {}
}
