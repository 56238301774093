import type {PlayerError} from 'amazon-ivs-player';

import {Event} from '../../../enums/Event';
import {ErrorEvent} from '../../../types/EventData';
import {EventCallbackDispatcher} from '../../internal/EventCallbackDispatcher';
import {PlayerContext} from '../player/AmazonIVSPlayerContext';

export class ErrorService {
  constructor(
    private dispatcher: EventCallbackDispatcher,
    private playerContext: PlayerContext,
  ) {}
  onError(payload: PlayerError) {
    const errorEvent: ErrorEvent = {
      currentTime: this.playerContext.position,
      code: payload.code,
      message: payload.message,
      data: {
        exceptionMessage: payload.message,
        exceptionStacktrace: [payload.source],
      },
    };
    this.dispatcher.eventCallback(Event.ERROR, errorEvent);
  }
}
