import type {AnalyticsConfig} from 'bitmovin-player';

import type {AdapterAPI} from '../../api/AdapterAPI';
import {Analytics} from '../../core/Analytics';
import type {CustomDataValues} from '../../types/CustomDataValues';
import {VERSION} from '../../Version';

import {BitmovinPwxInternalAdapter} from './BitmovinPwxInternalAdapter';
import type {AnalyticsContext} from './Types';

export class BitmovinPwxAdapter implements AdapterAPI {
  private readonly internalAdapter: BitmovinPwxInternalAdapter;
  private readonly analytics: Analytics;

  constructor(context: AnalyticsContext, config: AnalyticsConfig) {
    this.internalAdapter = new BitmovinPwxInternalAdapter(context);
    this.analytics = Analytics.create(config, this.internalAdapter);
  }

  dispose() {
    this.internalAdapter.release();
  }

  static readonly version: string = VERSION;

  get version(): string {
    return VERSION;
  }

  getCurrentImpressionId = () => this.analytics.getCurrentImpressionId();

  getUserId = () => this.analytics.getUserId();

  setCustomData = (values: CustomDataValues) => this.internalAdapter.setCustomData(values);

  setCustomDataOnce = (values: CustomDataValues) => this.analytics.setCustomDataOnce(values);

  sourceChange = (config: AnalyticsConfig) => this.analytics.sourceChange(config);
}
