import type {Quality} from 'amazon-ivs-player';

import QualityChangeService from '../../../core/QualityChangeService';
import {Event} from '../../../enums/Event';
import {EventCallbackDispatcher} from '../../internal/EventCallbackDispatcher';
import {AmazonIVSPlayerContext, PlayerContext} from '../player/AmazonIVSPlayerContext';

import {PauseEventDispatcher} from './PauseEventDispatcher';

export class PlaybackService {
  private ignorePause = false;
  constructor(
    private dispatcher: EventCallbackDispatcher,
    private playerContext: PlayerContext,
    private pauseDispatcher: PauseEventDispatcher,
    private qualityChangeService: QualityChangeService,
  ) {}

  onStartupPlaying() {
    this.ignorePause = true;
    // ignore pause for the first 100ms after startup
    // needed to prevent unnecessary pause sample at the beginning
    setTimeout(() => {
      this.ignorePause = false;
    }, 100);
  }

  onPause() {
    if (this.ignorePause) {
      return;
    }

    this.pauseDispatcher.schedulePauseEvent();
  }

  onPlaying() {
    this.pauseDispatcher.cancelPause();
    this.playing();
  }

  onRebuffering() {
    this.pauseDispatcher.cancelPause();
    this.startBuffering();
  }

  onQualityChange(payload: Quality) {
    this.qualityChange(payload);
  }

  onSeek() {
    this.seek();
  }

  onSeeked() {
    this.seeked();
  }

  private startBuffering() {
    const currentTime = this.playerContext.position;
    this.dispatcher.eventCallback(Event.START_BUFFERING, {
      currentTime,
    });
  }

  private playing() {
    const currentTime = this.playerContext.position;
    this.dispatcher.eventCallback(Event.PLAYING, {
      currentTime,
    });
  }

  private qualityChange(payload: Quality) {
    if (!this.qualityChangeService.shouldAllowVideoQualityChange(payload.bitrate)) {
      this.qualityChangeService.setVideoBitrate(payload.bitrate);
      return;
    }
    this.qualityChangeService.setVideoBitrate(payload.bitrate);
    const currentTime = this.playerContext.position;
    this.dispatcher.eventCallback(Event.VIDEO_CHANGE, {
      currentTime,
    });
  }

  private seek() {
    //Seeking for live streams is not supported, so seek method should do nothing for live streams
    if (this.playerContext.isLive) return;
    this.pauseDispatcher.cancelPause();
    const currentTime = this.playerContext.position;
    this.dispatcher.eventCallback(Event.SEEK, {currentTime});
  }

  private seeked() {
    //Seeking for live streams is not supported, so seek method should do nothing for live streams
    if (this.playerContext.isLive) return;
    this.pauseDispatcher.cancelPause();
    const currentTime = this.playerContext.position;
    this.dispatcher.eventCallback(Event.SEEKED, {currentTime});
  }

  static create(
    dispatcher: EventCallbackDispatcher,
    playerContext: AmazonIVSPlayerContext,
    qualityChangeService: QualityChangeService,
  ): PlaybackService {
    const pauseDispatcher = new PauseEventDispatcher(dispatcher, playerContext);
    return new PlaybackService(dispatcher, playerContext, pauseDispatcher, qualityChangeService);
  }
}
