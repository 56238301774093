import {ErrorCode} from './ErrorCode';

export class VideoStartFailedReason {
  static PAGE_CLOSED = new VideoStartFailedReason('PAGE_CLOSED', null);
  static PLAYER_ERROR = new VideoStartFailedReason('PLAYER_ERROR', null);
  static TIMEOUT = new VideoStartFailedReason('TIMEOUT', ErrorCode.VIDEO_STARTUP_TIMEOUT_REACHED);
  static UNKNOWN = new VideoStartFailedReason('UNKNOWN', null);

  private constructor(
    readonly reason: string,
    readonly errorCode: ErrorCode | null,
  ) {}
}
