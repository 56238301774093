import type {MediaPlayerClass} from 'dashjs';

import {Analytics} from '../../core/Analytics';
import {Event} from '../../enums/Event';
import {MIMETypes} from '../../enums/MIMETypes';
import {Player} from '../../enums/Player';
import {ErrorDetailBackend} from '../../features/errordetails/ErrorDetailBackend';
import {ErrorDetailTracking} from '../../features/errordetails/ErrorDetailTracking';
import {Feature} from '../../features/Feature';
import {FeatureConfig} from '../../features/FeatureConfig';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {CodecInfo} from '../../types/CodecInfo';
import {FeatureConfigContainer} from '../../types/FeatureConfigContainer';
import type {PlaybackInfo} from '../../types/PlaybackInfo';
import {QualityLevelInfo} from '../../types/QualityLevelInfo';
import {logger} from '../../utils/Logger';
import {isBlank, isValidString} from '../../utils/stringUtils';
import {HTML5InternalAdapter} from '../html5/HTML5InternalAdapter';
import {InternalAdapterAPI} from '../internal/InternalAdapterAPI';

export class DashjsInternalAdapter extends HTML5InternalAdapter implements InternalAdapterAPI {
  private readonly mediaPlayer: MediaPlayerClass;

  constructor(mediaPlayer: any, opts?: AnalyticsStateMachineOptions) {
    super(undefined, opts);
    this.mediaPlayer = mediaPlayer;
  }

  override initialize(analytics: Analytics): Array<Feature<FeatureConfigContainer, FeatureConfig>> {
    super.initialize(analytics);
    let videoEl: HTMLVideoElement | null = null;
    try {
      videoEl = this.mediaPlayer.getVideoElement();
    } catch (_e) {
      // empty
    }

    if (!videoEl) {
      this.mediaPlayer.on(
        'manifestLoaded',
        () => {
          try {
            videoEl = this.mediaPlayer.getVideoElement();
            this.setMediaElement(videoEl);
            this.registerMediaElementEventsForDashJS();
          } catch (e) {
            logger.errorMessageToUser(
              'Something went wrong while getting underlying HTMLVideoElement. Not possible to attach adapter and initialize Bitmovin Analytics. Error: ',
              e,
            );
            this.release();
          }
        },
        this,
      );
    } else {
      this.setMediaElement(videoEl);
      this.registerMediaElementEventsForDashJS();
    }
    const errorDetailTracking = new ErrorDetailTracking(
      analytics.errorDetailTrackingSettingsProvider,
      new ErrorDetailBackend(analytics.errorDetailTrackingSettingsProvider.collectorConfig),
      [analytics.errorDetailSubscribable],
      undefined,
    );
    return [errorDetailTracking];
  }

  registerMediaElementEventsForDashJS() {
    const mediaElement = this.mediaElement;

    if (!mediaElement) {
      return;
    }

    this.listenToMediaElementEvent('playing', () => {
      const {currentTime} = mediaElement;
      this.needsFirstPlayIntent = false;
      this.eventCallback(Event.TIMECHANGED, {
        currentTime,
      });
    });
  }

  override getPlayerName = () => Player.DASHJS;

  override getCurrentPlaybackInfo(): PlaybackInfo {
    const html5PlaybackInfo = super.getCurrentPlaybackInfo();

    const playbackInfo: PlaybackInfo = {
      ...html5PlaybackInfo,
    };

    const codecInfo = getMediaPlayerCodecInfo(this.mediaPlayer);
    this.sourceInfoFallbackService.applyAndStoreCodecInfo(playbackInfo, codecInfo);

    return playbackInfo;
  }

  getPlayerVersion = () => this.mediaPlayer.getVersion();

  override getMIMEType() {
    return MIMETypes.DASH;
  }

  override getStreamURL(): string | undefined {
    if (!this.mediaPlayer) {
      return undefined;
    }

    // In case of error/unloading the source might be undefined and the getter will throw an error
    let source: object | string | undefined = undefined;
    try {
      source = this.mediaPlayer.getSource();
    } catch (error) {
      logger.warn('no source available', error);
    }

    return source != null ? source.toString() : undefined;
  }
  /**
   * Implemented by sub-class to deliver current quality-level info
   * specific to media-engine.
   * @override
   * @returns {QualityLevelInfo}
   */
  getCurrentQualityLevelInfo(): null | QualityLevelInfo {
    if (!this.mediaPlayer) {
      return null;
    }

    // info not available on source change, methods exist but throw exception
    try {
      const videoBitrateInfoList = this.mediaPlayer.getBitrateInfoListFor('video');
      const currentVideoQualityIndex = this.mediaPlayer.getQualityFor('video');
      const currentVideoQuality = videoBitrateInfoList[currentVideoQualityIndex];

      if (currentVideoQuality == null) {
        return null;
      }

      const {width, height, bitrate} = currentVideoQuality;
      return {
        width,
        height,
        bitrate,
      };
    } catch (_error) {
      logger.warn('Quality information not available');
    }

    return null;
  }
}

/**
 * Build with player API with "dashjs@4.7.4"
 */
export const getMediaPlayerCodecInfo = (mediaPlayer: MediaPlayerClass): CodecInfo => {
  /**
   * Same RegExp for video and audio codec property parsing, because it's string value has same format with `codecs`
   *
   * Parse string and extract codec as first captured group, value between `\"` and `\"`
   *
   * Example 'video' value from player API:
   *    `video/mp4;codecs="avc1.4d400d"`
   * should be parsed by RegExp and return match
   *    `avc1.4d400d`
   *
   * Example 'audio' value from player API:
   *    `audio/mp4;codecs="mp4a.40.5"`
   * should be parsed by RegExp and return match
   *    `mp4a.40.5`
   */
  const codecsRegExp = new RegExp(/codecs="(.*)"/);

  // try-catches in code, because `mediaPlayer.getCurrentTrackFor`
  // can throw STREAMING_NOT_INITIALIZED_ERROR, if called before initializePlayback function
  //
  // see https://cdn.dashjs.org/latest/jsdoc/module-MediaPlayer.html#getCurrentTrackFor

  let videoCodec: string | undefined;
  try {
    const rawVideoCodec = mediaPlayer.getCurrentTrackFor('video')?.codec;
    if (isValidString(rawVideoCodec) && !isBlank(rawVideoCodec)) {
      const matches = codecsRegExp.exec(rawVideoCodec);
      videoCodec = matches?.[1];
    }
  } catch (_e) {
    videoCodec = undefined;
  }

  let audioCodec: string | undefined;
  try {
    const rawAudioCodec = mediaPlayer.getCurrentTrackFor('audio')?.codec;
    if (isValidString(rawAudioCodec) && !isBlank(rawAudioCodec)) {
      const matches = codecsRegExp.exec(rawAudioCodec);
      audioCodec = matches?.[1];
    }
  } catch (_e) {
    audioCodec = undefined;
  }

  return {videoCodec, audioCodec};
};
