const COLLECTOR_EVENTS = {
  RELEASE: 'RELEASE',
} as const;

const _ALL_COLLECTOR_EVENTS = [COLLECTOR_EVENTS.RELEASE] as const;

type CollectorEvent = (typeof _ALL_COLLECTOR_EVENTS)[number];

type Listener = () => void;

class CollectorEventBus {
  private observer: Record<CollectorEvent, Listener[]> = {
    [COLLECTOR_EVENTS.RELEASE]: [],
  };
  subscribe(event: CollectorEvent, func: Listener) {
    this.observer[event].push(func);
  }

  trigger(event: CollectorEvent) {
    this.observer[event].forEach((handler) => {
      handler();
    });
  }
}
const eventBus = new CollectorEventBus();

interface EventHandler {
  subscribe(func: Listener);
  trigger();
}

const buildEventHandler = (event: CollectorEvent): EventHandler => {
  return {
    subscribe(func) {
      eventBus.subscribe(event, func);
    },
    trigger() {
      eventBus.trigger(event);
    },
  };
};

const CollectorEvents: Record<CollectorEvent, EventHandler> = {
  RELEASE: buildEventHandler(COLLECTOR_EVENTS.RELEASE),
};

export default CollectorEvents;
