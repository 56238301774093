import type {MediaPlayer, Quality} from 'amazon-ivs-player';

export interface PlayerContext {
  position: number;
  isLive: boolean;
  currentQuality: Quality;
}

export class AmazonIVSPlayerContext implements PlayerContext {
  constructor(private player: MediaPlayer) {}

  get position(): number {
    return this.player.getPosition();
  }

  get isLive(): boolean {
    return this.player.getDuration() === Infinity;
  }

  get currentQuality(): Quality {
    return this.player.getQuality();
  }
}
