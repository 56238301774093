import {logger} from '../utils/Logger';

import {Feature} from './Feature';
import {FeatureConfig} from './FeatureConfig';

export class FeatureManager<TConfigContainer> {
  private features: Array<Feature<TConfigContainer, FeatureConfig>> = [];

  registerFeatures(features: Array<Feature<TConfigContainer, FeatureConfig>>) {
    this.features = features;
  }

  unregisterFeatures() {
    this.features.forEach((feature) => feature.disable());
    this.features = [];
  }

  resetFeatures() {
    this.features.forEach((feature) => feature.reset());
  }

  configureFeatures(authenticated: boolean, featureConfigs?: TConfigContainer) {
    this.features = this.features.filter((feature) => {
      const config = feature.configure(authenticated, featureConfigs);
      if (!authenticated || config?.enabled !== true) {
        logger.log(`Disabling feature ${feature.constructor.name} as it isn't enabled according to license callback.`);
        feature.disable();
        return false;
      }
      return true;
    });
    this.features.forEach((feature) => feature.enabled());
  }
}
