import type {AnalyticsConfig} from '../types/AnalyticsConfig';
import type {CustomDataValues} from '../types/CustomDataValues';

/**
 * Minimal set of shared methods among all collectors
 */
export interface AdapterAPI {
  get version(): string;
  getCurrentImpressionId: () => string | undefined;
  getUserId: () => string | undefined;
  setCustomData: (values: CustomDataValues) => void;
  setCustomDataOnce: (values: CustomDataValues) => void;
  sourceChange: (config: AnalyticsConfig) => void;
}

export type SsaiAdMetadata = {
  adId?: string;
  adSystem?: string;
  customData?: CustomDataValues;
};

export type AdPosition = 'preroll' | 'midroll' | 'postroll'; // eslint-disable-line perfectionist/sort-union-types

export type SsaiAdBreakMetadata = {
  adPosition?: AdPosition;
};

export type SsaiAdQuartile = 'first' | 'midpoint' | 'third' | 'completed'; // eslint-disable-line perfectionist/sort-union-types

export function isSsaiAdQuartile(anything: unknown): anything is SsaiAdQuartile {
  return anything === 'completed' || anything === 'first' || anything === 'midpoint' || anything === 'third';
}

export type SsaiAdQuartileMetadata = {
  failedBeaconUrl?: string;
};

export function isSsaiAdQuartileMetadata(anything: unknown): anything is SsaiAdQuartileMetadata {
  if (anything == null || typeof anything !== 'object') {
    return false;
  }

  // true (aka. invalid), when failedBeaconUrl was defined but not as a string, otherwise false (aka. valid).
  // Value `undefined` is considered also valid, because that value is optional in metadata
  const invalidFailedBeaconUrl = anything['failedBeaconUrl'] != null && typeof anything['failedBeaconUrl'] !== 'string';

  return !invalidFailedBeaconUrl;
}

export interface SsaiApi {
  adBreakStart(adBreakMetadata?: SsaiAdBreakMetadata): void;
  adStart(adMetadata?: SsaiAdMetadata): void;
  adQuartileFinished(adQuartile: SsaiAdQuartile, adQuartileMetadata?: SsaiAdQuartileMetadata): void;
  adBreakEnd(): void;
}
