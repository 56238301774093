import {ANALYTICS_QUALITY_CHANGE_COUNT_THRESHOLD} from '../utils/Settings';
import * as Settings from '../utils/Settings';

const INITIAL_VALUE = 0;

export default class QualityChangeService {
  private qualityChangeCount = INITIAL_VALUE;
  private previousVideoBitrate = INITIAL_VALUE;
  private previousAudioBitrate = INITIAL_VALUE;
  private resetIntervalId?: number;
  private startupHasFinished = false;

  constructor(private qualityChangeThreshold: number = ANALYTICS_QUALITY_CHANGE_COUNT_THRESHOLD) {}

  resetValues() {
    this.qualityChangeCount = INITIAL_VALUE;
    this.previousAudioBitrate = INITIAL_VALUE;
    this.previousVideoBitrate = INITIAL_VALUE;
    this.startupHasFinished = false;
    this.stopResetInterval();
  }

  isQualityChangeEventEnabled(): boolean {
    return this.qualityChangeCount <= this.qualityChangeThreshold;
  }

  increaseCounter() {
    this.qualityChangeCount++;
  }

  shouldAllowVideoQualityChange(newBitrate: number | undefined): boolean {
    return (
      this.startupHasFinished &&
      newBitrate != null &&
      !isNaN(newBitrate) &&
      this.previousVideoBitrate !== INITIAL_VALUE &&
      this.previousVideoBitrate !== newBitrate &&
      this.isQualityChangeEventEnabled()
    );
  }

  setVideoBitrate(newBitrate: number): void {
    // only bitrates after startup are relevant since some players
    // report initial bitrates before startup that differ from the
    // ones that are used after startup, which would lead to
    // wrong quality change events
    if (this.startupHasFinished) {
      this.previousVideoBitrate = newBitrate;
    }
  }

  setStartupHasFinished() {
    this.startupHasFinished = true;
  }

  shouldAllowAudioQualityChange(newBitrate: number | undefined): boolean {
    return (
      this.startupHasFinished &&
      newBitrate != null &&
      !isNaN(newBitrate) &&
      this.previousAudioBitrate !== INITIAL_VALUE &&
      this.previousAudioBitrate !== newBitrate &&
      this.isQualityChangeEventEnabled()
    );
  }

  setAudioBitrate(newBitrate: number): void {
    // only bitrates after startup are relevant since some players
    // report initial bitrates before startup that differ from the
    // ones that are used after startup, which would lead to
    // wrong quality change events
    if (this.startupHasFinished) {
      this.previousAudioBitrate = newBitrate;
    }
  }

  startResetInterval() {
    if (this.resetIntervalId !== undefined) {
      return;
    }

    this.resetIntervalId = window.setInterval(() => {
      this.resetCounter();
    }, Settings.ANALYTICS_QUALITY_CHANGE_COUNT_RESET_INTERVAL);
  }

  stopResetInterval() {
    if (this.resetIntervalId === undefined) {
      return;
    }

    window.clearInterval(this.resetIntervalId);
    delete this.resetIntervalId;
  }

  private resetCounter() {
    this.qualityChangeCount = 0;
  }
}
