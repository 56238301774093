export interface SubtitleInfo {
  /**
   * Indicates if subtitles are displayed.
   */
  enabled: boolean;
  /**
   * The selected subtitle/captions language.
   */
  language?: string;
}

export const isDifferentSubtitleInfo = (info1?: SubtitleInfo, info2?: SubtitleInfo): boolean => {
  if (info1 == null && info2 == null) {
    return false;
  }
  return !(info1 != null && info2 != null && info1.enabled === info2.enabled && info1.language === info2.language);
};
