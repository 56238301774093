import type {AdapterAPI} from '../../api/AdapterAPI';
import {Analytics} from '../../core/Analytics';
import type {AnalyticsConfig} from '../../types/AnalyticsConfig';
import type {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import type {CustomDataValues} from '../../types/CustomDataValues';
import {logger} from '../../utils/Logger';
import {
  hasPlayerAlreadyBeenAugmented,
  isDefined,
  markPlayerInstanceAsAugmented,
} from '../../utils/playerAugmentationUtils';
import {VERSION} from '../../Version';
import type {InternalAdapterAPI} from '../internal/InternalAdapterAPI';

import {AmazonIVSInternalAdapter} from './AmazonIVSInternalAdapter';
import {SourceUrlProvider} from './player/SourceUrlProvider';

export class AmazonIVSAdapter implements AdapterAPI {
  private readonly internalAdapter: InternalAdapterAPI | undefined;
  private readonly analytics: Analytics | undefined;

  constructor(config: AnalyticsConfig, player: any, opts?: AnalyticsStateMachineOptions) {
    if (hasPlayerAlreadyBeenAugmented(player)) {
      logger.errorMessageToUser('Bitmovin Analytics is already hooked up to this player instance');
      return;
    }
    markPlayerInstanceAsAugmented(player);

    const sourceUrlProvider = new SourceUrlProvider();
    this.internalAdapter = new AmazonIVSInternalAdapter(player, sourceUrlProvider, opts);
    this.analytics = Analytics.create(config, this.internalAdapter);

    this.wrapPlayerLoad(player, sourceUrlProvider);
  }

  private wrapPlayerLoad(player: any, sourceUrlProvider: SourceUrlProvider) {
    const originalLoad = player.load;

    player.load = (...args: Parameters<typeof player.load>): ProgressEvent<FileReader> => {
      sourceUrlProvider.m3u8Url = args[0] as string;
      return originalLoad.apply(player, args);
    };
  }

  static readonly version: string = VERSION;

  get version(): string {
    return VERSION;
  }

  getCurrentImpressionId(): string | undefined {
    if (!isDefined(this.analytics)) return;

    return this.analytics.getCurrentImpressionId();
  }

  getUserId(): string | undefined {
    if (!isDefined(this.analytics)) return;

    return this.analytics.getUserId();
  }

  setCustomData(values: CustomDataValues) {
    if (!isDefined(this.internalAdapter)) return;

    this.internalAdapter.setCustomData(values);
  }

  setCustomDataOnce(values: CustomDataValues) {
    if (!isDefined(this.analytics)) return;

    this.analytics.setCustomDataOnce(values);
  }

  sourceChange(config: AnalyticsConfig) {
    if (!isDefined(this.analytics)) return;

    this.analytics.sourceChange(config);
  }
}
