import {Sample} from '../types/Sample';

import {Backend} from './Backend';

/**
 * The `LongPlaytimeFilteringBackend` is a decorator class that filters out samples
 * where the `played` time exceeds a specified threshold.
 *
 * If a sample's `played` time surpasses the threshold, it is not forwarded to the next backend.
 * Otherwise, the sample is passed along to the next backend for further processing.
 *
 * @implements {Backend}
 */
export class LongPlaytimeFilteringBackend implements Backend {
  private backend: Backend;

  constructor(next: Backend) {
    this.backend = next;
  }

  sendRequest(sample: Sample) {
    if (this.isPlayedAboveThreshold(sample)) {
      return;
    }
    this.backend.sendRequest(sample);
  }

  sendUnloadRequest(sample: Sample) {
    if (this.isPlayedAboveThreshold(sample)) {
      return;
    }
    this.backend.sendUnloadRequest(sample);
  }

  sendRequestSynchronous(sample: Sample) {
    if (this.isPlayedAboveThreshold(sample)) {
      return;
    }
    this.backend.sendRequestSynchronous(sample);
  }

  sendAdRequest(sample: any) {
    this.backend.sendAdRequest(sample);
  }

  private isPlayedAboveThreshold(sample: Sample): boolean {
    return (sample.played == null ? 0 : sample.played) >= 90000;
  }
}
