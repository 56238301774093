import {FeatureConfig} from './FeatureConfig';

export abstract class Feature<TConfigContainer, TConfig extends FeatureConfig> {
  private _config?: TConfig = undefined;
  private _isEnabled = true;

  get config(): TConfig | undefined {
    return this._config;
  }

  get isEnabled(): boolean {
    return this._isEnabled;
  }

  abstract reset(): void;
  abstract enabled(): void;
  abstract disabled(): void;
  abstract configured(authenticated: boolean, config?: TConfig): void;

  disable() {
    this._isEnabled = false;
    this.disabled();
  }

  configure(authenticated: boolean, configContainer?: TConfigContainer): TConfig | undefined {
    if (configContainer != null) {
      this._config = this.extractConfig(configContainer);
    }
    this.configured(authenticated, this._config);
    return this._config;
  }

  abstract extractConfig(configContainer: TConfigContainer): TConfig | undefined;
}
