import {logger} from './Logger';

export function post(url: string, body: any, callback: any, async = true): void {
  let xhttp: any;
  let isLegacyXDomainRequestUsed = false;

  if ((window as any).XDomainRequest) {
    // XDomainRequest is the only way of having an XHR that supports CORS in IE8 and 9. At the time of IE8,
    // Microsoft decided to come up with their own CORS XHR instead of the standard CORS XMLHttpRequest
    // which is now used in IE10.
    // Since IE10, XDomainRequest has been deprecated and since IE11 removed.
    xhttp = new (window as any).XDomainRequest();
    isLegacyXDomainRequestUsed = true;
  } else {
    xhttp = new XMLHttpRequest();
  }

  const responseCallback = () => {
    if (xhttp.readyState == XMLHttpRequest.DONE) {
      if (xhttp.responseText <= 0) {
        return;
      }

      const sampleResponse = JSON.parse(xhttp.responseText);

      callback(sampleResponse);
    }
  };

  if (isLegacyXDomainRequestUsed) {
    xhttp.onload = responseCallback;
  } else {
    xhttp.onreadystatechange = responseCallback;
  }

  try {
    xhttp.open('POST', url, async);

    // XDomainRequest restriction: No custom headers may be added to the request
    // https://learn.microsoft.com/en-us/archive/blogs/ieinternals/xdomainrequest-restrictions-limitations-and-workarounds
    if (!isLegacyXDomainRequestUsed) {
      xhttp.setRequestHeader('Content-Type', 'text/plain');
    }

    xhttp.send(JSON.stringify(body));
  } catch (e) {
    logger.error('Failed to send POST request.', e);
  }
}
