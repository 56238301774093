import {appendSsaiRoutingParam, isSsaiRelatedSample} from '../core/ssai/ssaiUtils';
import type {AdSample, Sample} from '../types/Sample';

import {post} from './Http';
import {joinUrls, noOp} from './Utils';

export class AnalyticsCall {
  private readonly analyticsServerUrl: string;
  private readonly adAnalyticsServerUrl: string;

  constructor(baseUrl: string) {
    this.analyticsServerUrl = joinUrls(baseUrl, 'analytics');
    this.adAnalyticsServerUrl = joinUrls(baseUrl, 'analytics/a');
  }

  sendRequest(sample: Sample, callback: () => void) {
    let analyticsUrl = this.analyticsServerUrl;
    if (isSsaiRelatedSample(sample)) {
      analyticsUrl = appendSsaiRoutingParam(analyticsUrl);
    }

    post(analyticsUrl, sample, callback);
  }

  sendUnloadRequest(sample: Sample) {
    const sendBeaconFallback = (data: Sample) => this.sendRequestSynchronous(data, noOp);

    let analyticsUrl = this.analyticsServerUrl;
    if (isSsaiRelatedSample(sample)) {
      analyticsUrl = appendSsaiRoutingParam(analyticsUrl);
    }

    this.trySendBeacon(sendBeaconFallback, analyticsUrl, sample);
  }

  private trySendBeacon(
    fallbackFunction: (data: any) => void,
    url: string,
    data: unknown,
    requestHeaders: {[headerName: string]: string} = {},
  ) {
    if (typeof navigator.sendBeacon === 'undefined') {
      fallbackFunction(data);
    } else {
      /**
       * With a Blob we can send payload but also http headers
       * @see https://stackoverflow.com/questions/40523469/navigator-sendbeacon-to-pass-header-information
       */
      const blob = new Blob([JSON.stringify(data)], requestHeaders);
      const success = navigator.sendBeacon(url, blob);
      if (!success) {
        fallbackFunction(data);
      }
    }
  }

  sendRequestSynchronous(sample: Sample, callback: () => void) {
    let analyticsUrl = this.analyticsServerUrl;
    if (isSsaiRelatedSample(sample)) {
      analyticsUrl = appendSsaiRoutingParam(analyticsUrl);
    }

    post(analyticsUrl, sample, callback, false);
  }

  sendAdRequest(adSample: AdSample) {
    let adAnalyticsUrl = this.adAnalyticsServerUrl;
    if (isSsaiRelatedSample(adSample)) {
      adAnalyticsUrl = appendSsaiRoutingParam(adAnalyticsUrl);
    }

    const sendBeaconFallback = (data: AdSample) => post(adAnalyticsUrl, data, noOp);
    this.trySendBeacon(sendBeaconFallback, adAnalyticsUrl, adSample);
  }
}
