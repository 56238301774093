import QualityChangeService from '../../../core/QualityChangeService';
import {Event} from '../../../enums/Event';
import {EventCallbackDispatcher} from '../../internal/EventCallbackDispatcher';
import {PlayerContext} from '../player/AmazonIVSPlayerContext';

enum StartupState {
  SETUP,
  IDLE,
  STARTUP,
  STARTUP_FINISHED,
}

export class VideoStartupService {
  constructor(
    private dispatcher: EventCallbackDispatcher,
    private playerContext: PlayerContext,
    private qualityChangeService: QualityChangeService,
  ) {}

  private state: StartupState = StartupState.SETUP;

  get finishedStartup(): boolean {
    return this.state == StartupState.STARTUP_FINISHED;
  }

  onInitialized() {
    this.finishSetup();
  }

  onPlay() {
    this.startStartup();
  }

  onPlaying() {
    this.finishStartup();
  }

  private finishSetup() {
    this.state = StartupState.IDLE;
    const currentTime = this.playerContext.position;
    this.dispatcher.eventCallback(Event.READY, {
      currentTime,
    });
  }

  private startStartup() {
    if (this.state != StartupState.IDLE) {
      return;
    }

    this.state = StartupState.STARTUP;
    const currentTime = this.playerContext.position;
    this.dispatcher.eventCallback(Event.PLAY, {
      currentTime,
    });
  }

  private finishStartup() {
    if (this.state != StartupState.STARTUP) {
      return;
    }

    this.state = StartupState.STARTUP_FINISHED;
    const currentTime = this.playerContext.position;
    this.dispatcher.eventCallback(Event.PLAYING, {
      currentTime,
    });

    this.qualityChangeService.resetValues();
    this.qualityChangeService.setStartupHasFinished();
  }
}
