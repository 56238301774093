import {AnalyticsEventBase} from '../types/EventData';

export const DEFAULT_HEARTBEAT_INTERVAL_MS = 59700;

export interface HeartbeatListener {
  onHeartbeat: (eventObject: AnalyticsEventBase) => void;
}

export class HeartbeatService {
  private heartbeatInterval: number | undefined;
  private listener: HeartbeatListener | undefined;
  private readonly getPlayerCurrentTime: () => number;

  constructor(getPlayerCurrentTime: () => number) {
    this.getPlayerCurrentTime = getPlayerCurrentTime;
  }

  setListener(listener: HeartbeatListener) {
    this.listener = listener;
  }

  startHeartbeat() {
    if (this.heartbeatInterval !== undefined) {
      this.clearHeartbeat();
    }
    this.heartbeatInterval = window.setInterval(() => {
      const eventData: AnalyticsEventBase = {
        currentTime: this.getPlayerCurrentTime(),
      };
      this.listener?.onHeartbeat(eventData);
    }, DEFAULT_HEARTBEAT_INTERVAL_MS);
  }

  stopHeartbeat() {
    this.clearHeartbeat();
  }

  private clearHeartbeat() {
    window.clearTimeout(this.heartbeatInterval);
    this.heartbeatInterval = undefined;
  }
}
