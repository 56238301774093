export class Queue<T> {
  private readonly _items: T[] = [];

  get items(): ReadonlyArray<T> {
    return this._items;
  }

  get length() {
    return this._items.length;
  }

  offer(item: T) {
    this._items.push(item);
  }

  poll(): T | undefined {
    return this._items.shift();
  }

  clear() {
    this._items.splice(0);
  }

  limit(maxElements: number) {
    while (this._items.length > maxElements) {
      this.poll();
    }
  }
}
