export class AdCallbacks {
  onAdStarted = (_event: any): void => undefined;
  onAdFinished = (_event: any): void => undefined;
  onAdBreakStarted = (_event: any): void => undefined;
  onAdBreakFinished = (_event: any): void => undefined;
  onAdClicked = (_event: any): void => undefined;
  onAdError = (_event: any): void => undefined;
  onAdManifestLoaded = (_event: any): void => undefined;
  onAdQuartile = (_event: any): void => undefined;
  onAdSkipped = (_event: any): void => undefined;
  onPlay = (_issuer?: string): void => undefined;
  onPause = (_issuer?: string): void => undefined;
  onBeforeUnload = (): void => undefined;
}

export interface AdModuleAPI {
  readonly adCallbacks: AdCallbacks;
  isLinearAdActive: () => boolean;
  getContainer: () => HTMLElement;
  getAdModuleInfo: () => {name?: string; version?: string};
  currentTime: () => number;
}
