import {LicensingResponse} from '../types/LicensingRequest';

import {post} from './Http';
import {joinUrls} from './Utils';

export function LicenseCall(key: string, domain: string, version: string, baseUrl: string): Promise<LicensingResponse> {
  const licenseServerUrl = joinUrls(baseUrl, '/licensing');
  const licensingRequest = {
    analyticsVersion: version,
    domain,
    key,
  };

  return new Promise<LicensingResponse>((resolve) => {
    post(licenseServerUrl, licensingRequest, (response) => {
      resolve(response);
    });
  });
}
