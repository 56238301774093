import {AdAnalyticsSample} from '../types/AdAnalyticsSample';
import {AdBreakSample} from '../types/AdBreakSample';
import {AdSample} from '../types/AdSample';
import type {AnalyticsConfig, AnalyticsDebugConfig} from '../types/AnalyticsConfig';
import {Sample} from '../types/Sample';
import {logger} from '../utils/Logger';

import {Backend} from './Backend';

/**
 * The `DebuggingBackend` is responsible for printing debugging information
 * before forwarding the requests to the specified underlying backend.
 *
 * This allows for inspection of specific fields in the sample data before it is sent.
 * The fields can be specified through the {@link AnalyticsDebugConfig#fields}.
 *
 * @implements {Backend}
 * */
export class DebuggingBackend implements Backend {
  private underlying: Backend;
  private debugFields?: string[];

  constructor(config: AnalyticsConfig, underlying: Backend) {
    this.underlying = underlying;
    if (config.debug && (config.debug as AnalyticsDebugConfig).fields) {
      this.debugFields = (config.debug as AnalyticsDebugConfig).fields;
    }
  }

  sendRequest(sample: Sample) {
    this.printFields(sample);
    this.underlying.sendRequest(sample);
  }

  sendUnloadRequest(sample: Sample) {
    this.printFields(sample);
    this.underlying.sendUnloadRequest(sample);
  }

  sendRequestSynchronous(sample: Sample) {
    this.printFields(sample);
    this.underlying.sendRequestSynchronous(sample);
  }

  sendAdRequest(sample: AdAnalyticsSample & AdBreakSample & AdSample) {
    this.underlying.sendAdRequest(sample);
  }

  private printFields(sample: Sample) {
    if (this.debugFields && this.debugFields.length) {
      const fields = {};
      this.debugFields.forEach((f) => (fields[f] = sample[f]));
      logger.table([fields]);
    }
  }
}
