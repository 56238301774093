import {AnalyticsConfig} from '../types/AnalyticsConfig';

/**
 * Uses a Singleton design pattern.
 *
 * We want to ensure that only one object of its kind exists and provides a single point of access to it,
 * to be able to control enabled/disabled `console` logs for customer in whole code base.
 */
class Logger {
  private static instance: Logger;

  static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }

    return Logger.instance;
  }

  private analyticsDebugConfig: Readonly<AnalyticsConfig['debug']>;

  private constructor() {}

  initialize(analyticsDebugConfig: AnalyticsConfig['debug']) {
    this.analyticsDebugConfig = analyticsDebugConfig;
  }

  //#region Console API
  log(message?: unknown, ...optionalParams: unknown[]) {
    if (!this.enabled) {
      return;
    }
    console.log(message, optionalParams); //eslint-disable-line no-console
  }

  table(tabularData: unknown, properties?: readonly string[]) {
    if (!this.enabled) {
      return;
    }
    console.table(tabularData, properties); //eslint-disable-line no-console
  }

  warn(message?: unknown, ...optionalParams: unknown[]) {
    if (!this.enabled) {
      return;
    }
    console.warn(message, optionalParams); //eslint-disable-line no-console
  }

  error(message?: unknown, ...optionalParams: unknown[]) {
    if (!this.enabled) {
      return;
    }
    console.error(message, optionalParams); //eslint-disable-line no-console
  }

  /**
   * Outputs an error message to user/customer console.
   *
   * Does not relay on {@link Logger#enabled}! because it should deliver the error information to user/customer.
   */
  errorMessageToUser(message?: unknown, ...optionalParams: unknown[]) {
    console.error(message, ...optionalParams); //eslint-disable-line no-console
  }
  //#endregion

  /** `true` if {@link AnalyticsConfig#debug} was set */
  private get enabled() {
    return (this.analyticsDebugConfig as boolean) || false;
  }
}

export const padRight = (str: string | undefined, length: number) => {
  const padStr = new Array(length).join(' ');
  return (str + padStr).slice(0, length);
};

export const logger = Logger.getInstance();
