export interface Subscribable<TArgs> {
  subscribe(callback: EventHandler<TArgs>): () => void;
  unsubscribe(callback: EventHandler<TArgs>);
}

type EventHandler<TArgs> = (args: TArgs) => void;

export class EventDispatcher<TArgs> implements Subscribable<TArgs> {
  private callbacks: Array<EventHandler<TArgs>> = [];

  subscribe(callback: EventHandler<TArgs>): () => void {
    this.callbacks.push(callback);

    return () => this.unsubscribe(callback);
  }

  dispatch(args: TArgs) {
    const callbacks = this.callbacks.slice(0);
    callbacks.forEach((callback) => {
      callback(args);
    });
  }

  unsubscribe(callback: EventHandler<TArgs>) {
    const index = this.callbacks.indexOf(callback);
    if (index > -1) {
      this.callbacks.splice(index, 1);
    }
  }

  get subscriberCount() {
    return this.callbacks.length;
  }
}
