import {SsaiAdBreakMetadata, SsaiAdMetadata, SsaiAdQuartile, SsaiAdQuartileMetadata} from '../../api/AdapterAPI';

export type InternalSsaiMetadata = SsaiAdBreakMetadata & SsaiAdMetadata;

type SsaiEngagementBaseData = {
  adIndex: number;
  currentAdImpressionId: string;
  currentAdMetadata: InternalSsaiMetadata;
  type: 'error' | 'quartile' | 'started';
};

export type SsaiEngagementErrorData = {
  errorCode: number;
  errorMessage: string;
  timeSinceAdStartedInMs: number | undefined;
  type: 'error';
} & SsaiEngagementBaseData;

export function isSsaiEngagementErrorData(data: SsaiEngagementInteractionData): data is SsaiEngagementErrorData {
  return data.type === 'error';
}

export type SsaiEngagementQuartileData = {
  quartile: SsaiAdQuartile;
  quartileMetadata: SsaiAdQuartileMetadata;
  timeSinceAdStartedInMs: number | undefined;
  type: 'quartile';
} & SsaiEngagementBaseData;

export function isSsaiEngagementQuartileData(data: SsaiEngagementInteractionData): data is SsaiEngagementQuartileData {
  return data.type === 'quartile';
}

export type SsaiEngagementStartedData = {type: 'started'} & SsaiEngagementBaseData;

export function isSsaiEngagementStartedData(data: SsaiEngagementInteractionData): data is SsaiEngagementStartedData {
  return data.type === 'started';
}

export type SsaiEngagementInteractionData =
  | SsaiEngagementErrorData
  | SsaiEngagementQuartileData
  | SsaiEngagementStartedData;
