import {PAGE_LOAD_TYPE} from '../enums/PageLoadType';
import type {CustomDataValues} from '../types/CustomDataValues';
import * as CustomDataUtils from '../types/CustomDataValues';

import {endsWith, startsWith} from './stringUtils';

export const isBoolean = (bool: unknown): bool is boolean => bool != null && typeof bool === 'boolean';

export const isNumber = (num: unknown): num is number => num != null && typeof num === 'number';

export const sanitizePath = (path: string): string => {
  return path.replace(/\/$/g, '');
};

export const calculateTimeWithUndefined = (time?: number): number | undefined => {
  if (time === undefined) {
    return time;
  }
  time = time * 1000;
  return Math.round(time);
};

export const calculateTime = (time: number): number => {
  time = time * 1000;
  return Math.round(time);
};

/**
 * @deprecated Use directly `Date.now()` instead
 *
 * Return the current time in milliseconds.
 */
export const getCurrentTimestamp = (): number => {
  return Date.now();
};

export const generateUUID = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

/**
 * Empty function returns undefined
 *
 * @deprecated
 */
export const noOp = () => undefined;

export const getCustomDataString = (customData: any): string | undefined => {
  if (typeof customData === 'object') {
    return JSON.stringify(customData);
  } else if (typeof customData === 'function') {
    return getCustomDataString(customData());
  } else if (typeof customData === 'undefined') {
    return customData;
  } else if (typeof customData !== 'string') {
    return String(customData);
  }

  return customData;
};

export const transferCustomDataFields = (fromConfig: CustomDataValues, toConfig: CustomDataValues) => {
  for (let i = 1; i <= CustomDataUtils.customDataFieldCount; i++) {
    const customDataField = `customData${i}`;
    toConfig[customDataField] = getCustomDataString(fromConfig[customDataField]);
  }
};

export const getDocumentPropWithPrefix = (prop: string): string | undefined => {
  const prefixes = ['webkit', 'moz', 'ms', 'o'];
  if (prop in document) {
    return prop;
  }

  const pascalCase = prop.charAt(0).toUpperCase() + prop.slice(1);
  for (const prefix of prefixes) {
    if (prefix + pascalCase in document) {
      return prefix + pascalCase;
    }
  }
  return undefined;
};

export const isVideoInFullscreen = () => {
  const fullscreenProp = getDocumentPropWithPrefix('fullscreenElement');
  return fullscreenProp !== undefined && document[fullscreenProp] && document[fullscreenProp].nodeName === 'VIDEO';
};

export const getHiddenProp = (): string | undefined => {
  return getDocumentPropWithPrefix('hidden');
};

export function getPageLoadType(): PAGE_LOAD_TYPE {
  const hiddenProp = getHiddenProp();
  if (hiddenProp != null && document[hiddenProp] === true) {
    return PAGE_LOAD_TYPE.BACKGROUND;
  }
  return PAGE_LOAD_TYPE.FOREGROUND;
}

export const getHostnameAndPathFromUrl = (url: string) => {
  const domElement = document.createElement('a');
  domElement.href = url;

  return {hostname: domElement.hostname, path: domElement.pathname};
};

export const calculatePercentage = (numerator?: number, denominator?: number): number | undefined => {
  if (denominator === undefined || denominator === 0) {
    return undefined;
  }
  return Math.round(((numerator || 0) / denominator) * 100);
};

export const getURLResourceName = (url: string): string => {
  if (url.length === 0) {
    return '';
  }
  return url.split('/').pop() || '';
};

export const joinUrls = (...args: string[]): string => {
  return args.reduce((prev, current) => {
    const prevUrlValue = endsWith(prev, '/') && current !== undefined ? prev.substr(0, prev.length - 1) : prev;
    const nextUrlValue = startsWith(current, '/') ? current.substr(1) : current;
    return prevUrlValue + '/' + nextUrlValue;
  });
};
