import {FeatureConfigContainer} from './FeatureConfigContainer';

export interface LicensingRequest {
  key?: string;
  domain: string;
  version: string;
}

export enum LicensingResult {
  Denied = 'denied',
  Granted = 'granted',
  Skip = 'skip',
}

export interface LicensingResponse {
  status: LicensingResult;
  message: string;
  features?: FeatureConfigContainer;
}

export type LicenseCallFunc = (
  key: string,
  domain: string,
  version: string,
  baseUrl: string,
) => Promise<LicensingResponse>;
